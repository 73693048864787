/**
 * @author      Abdul Pathan
 * @date        September, 2024
 * @copyright   www.ibirdsservices.com
 */

import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { Form, } from "react-bootstrap";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import WhatsAppAPI from '../../api/WhatsAppAPI';

const SendFileModal = (props) => {
    const [files, setFiles] = useState([]);
    const [isSending, setIsSending] = useState(false);
    const [isFileSelected, setIsFileSelected] = useState(false);
    const [parentRecord, setParentRecord] = useState(props.parentData || {});
    const [fileDescription, setFileDescription] = useState('');

    const MIMETypeMap = new Map([
        ["audio/aac", "aac"],
        ["audio/mp4", "mp4"],
        ["audio/mpeg", "mpeg"],
        ["audio/amr", "amr"],
        ["audio/ogg", "ogg"],
        ["audio/opus", "opus"],
        ["application/vnd.ms-powerpoint", "ppt"],
        ["application/msword", "doc"],
        ["application/vnd.openxmlformats-officedocument.wordprocessingml.document", "docx"],
        ["application/vnd.openxmlformats-officedocument.presentationml.presentation", "pptx"],
        ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "xlsx"],
        ["application/pdf", "pdf"],
        ["text/plain", "txt"],
        ["application/vnd.ms-excel", "xls"],
        ["image/jpeg", "jpeg"],
        ["image/png", "png"],
        ["image/webp", "webp"],
        ["video/mp4", "mp4"],
        ["video/3gpp", "3gpp"],
    ]);

    const MAX_FILE_SIZE_MB = 1 * 1024 * 1024;


    const handleChange = (event) => {
        const selectedFiles = event.target.files;
        let isValid = true;

        Array.from(selectedFiles).forEach((file) => {
            if (!MIMETypeMap.has(file.type)) {
                isValid = false;
                toast.error(`Invalid file type: ${file.name}. Please upload a valid file.`);
            } else if (file.size > MAX_FILE_SIZE_MB) {
                isValid = false;
                toast.error(`File size too large: ${file.name}. Maximum allowed size is 3MB.`);
            }
        });

        if (isValid) {
            setFiles(selectedFiles);
            setIsFileSelected(selectedFiles.length > 0);
        } else {

            event.target.value = null;
        }
    };

    const getWhatsAppType = (mimeType) => {
        if (mimeType.startsWith('image/')) return 'IMAGE';
        if (mimeType.startsWith('video/')) return 'VIDEO';
        if (mimeType.startsWith('audio/')) return 'AUDIO';
        if (mimeType === 'application/pdf') return 'DOCUMENT';
        if (mimeType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || mimeType === 'application/msword') return 'DOCUMENT';
        if (mimeType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || mimeType === 'application/vnd.ms-excel') return 'DOCUMENT';
        // if (mimeType === 'text/csv') return 'DOCUMENT';

        return 'DOCUMENT';
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (parentRecord.id) {
            if (files.length > 0) {

                setIsSending(true); // Set submitting state to true

                // let current = new Date();
                const formData = new FormData();
                formData.append("messaging_product", "whatsapp");
                formData.append("file", files[0]);
                formData.append(`description`, fileDescription);

                try {
                    const documentId = await WhatsAppAPI.uploadDocumentWithApi(formData,props.selectedWhatsAppSetting);
                    if (documentId.id) {
                        const fileType = getWhatsAppType(files[0].type);

                        const reqBody = {
                            messaging_product: 'whatsapp',
                            recipient_type: 'individual',
                            to: parentRecord.whatsapp_number,
                            type: fileType, [fileType.toLowerCase()]: {
                                id: documentId.id
                            }
                        };
                        const response = await WhatsAppAPI.sendSingleWhatsAppTextMessage(reqBody,props.selectedWhatsAppSetting);
                        if (response.messaging_product === 'whatsapp') {
                            const result = await WhatsAppAPI.createFile(parentRecord.id, formData);

                            if (result.errors) {
                                toast.error('Bad Request.');
                            }

                            if (result.success) {
                                const newMessage = {
                                    parent_id: parentRecord.id || null,
                                    name: parentRecord.contactname || '',
                                    message_template_id: null,
                                    whatsapp_number: parentRecord.whatsapp_number,
                                    message: '',
                                    status: 'Outgoing',
                                    recordtypename: props?.filterData?.recordType || '',
                                    file_id: result.records[0].id || null,
                                    business_number: props.selectedWhatsAppSetting,
                                    // website: '',
                                    // call: '',
                                    // copy_code: '',
                                    is_read: true
                                }

                                const response = await WhatsAppAPI.insertMsgHistoryRecords(newMessage);

                                toast.success('File sent successfully!');
                                props.refreshImageData();
                            }
                            else {
                                toast.error('Failed to send file.');
                            }
                        }
                    } else {
                        toast.error('An error occurred while sending the file.');
                    }
                } catch (error) {
                    console.error('Error during file upload:', error);
                    toast.error('An error occurred while sending the file.');
                } finally {
                    setIsSending(false); // Reset submitting state
                }
            } else {
                toast.error('Please select a file.');
            }
        }
    };


    return (

        <Modal {...props} aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Upload Files
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Form.Group controlId="formFile" className="mb-3">
                    <Form.Label></Form.Label>
                    <Form.Control type="file" onChange={handleChange} accept={[...MIMETypeMap.keys()].join(',')} />
                </Form.Group>

                <Form.Group controlId="formBasicDescription">
                    <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicDescription"
                    >
                        Description
                    </Form.Label>
                    <Form.Control
                        as="textarea"
                        name="description"
                        placeholder="Enter description"
                        onChange={(e) => setFileDescription(e.target.value)}
                    />
                </Form.Group>

            </Modal.Body>
            <Modal.Footer>
                <Button variant="light" onClick={props.onHide}>
                    Close
                </Button>
                <div className="submit">
                    <Button variant="outline-primary" type="button" onClick={handleSubmit} disabled={!isFileSelected || isSending}>
                        {isSending ? 'Sending...' : 'Send'}
                    </Button>
                </div>
            </Modal.Footer>
        </Modal >
    )
}

export default SendFileModal
